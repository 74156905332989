import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Scroller from "../components/scroller"
import PortfolioModal from "../components/portfolio/modal"
import PortfolioCarousel from "../components/portfolio/carousel"

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this)
    this.state = {
      modalShow: false,
      modalCurrent: 0,
    }
    this.handlePortfolioClick = this.handlePortfolioClick.bind(this)
    this.setModal = this.setModal.bind(this)
  }

  handlePortfolioClick(index, e) {
    e.preventDefault()
    this.setModal(true, index)
  }

  setModal(isShown, current) {
    this.setState({
      modalShow: isShown,
      modalCurrent: current,
    })
  }

  render() {
    return (
      <Layout>
        <header className="masthead">
          <div className="container h-100">
            <div className="row h-100 align-items-center justify-content-center text-center">
              <div className="col-lg-10 align-self-end">
                <h1 className="text-uppercase text-white font-weight-bold">
                  A-1 Registered Black Angus
                </h1>
                <hr className="divider my-4" />
              </div>
              <div className="col-lg-8 align-self-baseline">
                <p className="text-white-75 font-weight-light mb-5">
                  We would like to thank all of our customers for their
                  continued support and look forward to doing more business in
                  the future. If we can help you in any way, please let us know.
                  We look forward to hearing from you!
                </p>
                <a
                  className="btn btn-primary btn-xl js-scroll-trigger"
                  href="#contact"
                  onClick={Scroller.handleAnchorScroll}
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </header>
        <SEO title="Thames Angus Farm | Mississippi Angus" />
        <section className="page-section bg-primary" id="about">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 className="text-white mt-0">How to find us</h2>
                <hr className="divider light my-4" />
                <p className="text-white-50 mb-4">
                  We're located 10 minutes west of Monticello, Mississippi
                </p>
                <iframe
                  width="600"
                  height="450"
                  frameBorder="0"
                  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAWlTHiDmVvsczvO7omUE37t1RVxE_V8F8
    &q=382+E+Lincoln+Rd,Monticello+MS"
                  allowFullScreen
                  style={{ maxWidth: "100%" }}
                ></iframe>
                <a
                  className="btn btn-light btn-xl js-scroll-trigger mt-5"
                  href="https://www.google.com/maps/dir//382+E+Lincoln+Rd,+Monticello,+MS+39654/@31.491669,-90.206162,16z"
                  target="_blank"
                >
                  Get Directions
                </a>
              </div>
            </div>
          </div>
        </section>

        <section id="portfolio">
          <div className="container-fluid p-0">
            <div className="row no-gutters">
              {this.props.data.images.edges.map((image, i) =>
                <div className="col-lg-4 col-sm-6">
                  <a
                    className="portfolio-box"
                    href={`img/portfolio/fullsize/${i+1}.jpg`}
                    onClick={this.handlePortfolioClick.bind(this, this.props.data.images.edges.length-i-1)}
                  >
                    <Img
                      fluid={
                        image.node.childImageSharp.fluid
                      }
                    />
                    <div className="portfolio-box-caption">
                      <div className="project-category text-white-50">
                        Click To
                      </div>
                      <div className="project-name">View Larger Image</div>
                    </div>
                  </a>
                </div>
              )}
            </div>
          </div>
        </section>

        <section className="page-section" id="contact">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 className="mt-0">Let's Get In Touch!</h2>
                <hr className="divider my-4" />
                <p className="text-muted mb-5">
                  Now for sale at the Farm: Registered Angus bulls thick and
                  stout with top AI genetics selected for optimum birth weight,
                  weaning weight, carcass qualities, and maternal traits.
                  Registered Angus heifers bred to easy calving Angus bulls.
                  Open heifers also available that are ready to breed.
                  Registered Angus cows and pairs. Angus/Source commercial
                  heifers are also available.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 ml-auto text-center mb-5 mb-lg-0">
                <i className="fas fa-phone fa-3x mb-3 text-muted"></i>
                <a href="tel:+16017570673" className="d-block">
                  +1 (601) 757-0673
                </a>
              </div>
              <div className="col-lg-4 mr-auto text-center">
                <i className="fas fa-envelope fa-3x mb-3 text-muted"></i>
                <a className="d-block" href="mailto:jake@thamesangusfarm.com">
                  jake@thamesangusfarm.com
                </a>
              </div>
            </div>
          </div>
        </section>
        <PortfolioModal
          show={this.state.modalShow}
          onHide={() => this.setModal(false, 0)}
        >
          <PortfolioCarousel
            images={this.props.data.images.edges}
            current={this.state.modalCurrent}
          />
        </PortfolioModal>
      </Layout>
    )
  }
}

export const imageData = graphql`
  query {
    images: allFile(
      filter: { relativePath: { glob: "portfolio/fullsize/*.jpg" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    file(relativePath: { eq: "gatsby-icon.png" }) {
      childImageSharp {
        fixed(width: 35, height: 35) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
